import moment from "moment";
import Button from "@material-ui/core/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { GetAllCsvData } from "../../redux/reducers/EventReducer";
import FullPageLoader from "./FullPageLoader";
import { useState } from "react";
import { stringify } from "csv-stringify";
import { useDispatch, useSelector } from "react-redux";
import { generateToast } from "../../helper";
import { ERROR } from "../../constant";
import { convertNumToFormatString } from "../../helper/CostHelpers";
const CsvExport = ({ mode }) => {
  const Headers = [
    "Created At",
    "Event Date",
    "Client Name",
    "Client Type",
    "School/Region",
    "Sold By",
    "Created By",
    "Amount",
    "Cost",
    "Profit",
    "Profit %",
    "Status",
    "Sign Date",
    "Invoiced?",
  ];
  const dispatch = useDispatch();
  const [csvLoading, setCsvLoading] = useState(false);
  const {
    Contracts: { filter },
  } = useSelector((state) => state);

  const handleExport = async (filter) => {
    setCsvLoading(true);
    const csvDataPayload = await dispatch(GetAllCsvData(filter));
    const AllCsvData = csvDataPayload?.payload;

    const csvData = AllCsvData?.data?.slice(1);
    const CSVFileName = filter
      ? `TPE-Filtered-Contract-${filter.filter.startEventDate || "N/A"}-${
          filter.filter.endEventDate || "N/A"
        }`
      : "TPE-CONTRACTS-ALL";


    const formattedData = csvData?.map((event) => {
      return [
        moment(event?.[32]).format("MM/DD/YYYY"),
        moment(event?.[2]).format("MM/DD/YYYY"),
        event?.[42][0]?.client?.name ?? "N/A",
        event?.[36] ?? "N/A",
        event?.[42][0]?.collaborator?.name || event[37] || "N/A",
        event?.[40] || "N/A",
        event?.[41] || "N/A",
        `$${convertNumToFormatString(event?.[10] || 0)}`,
        `$${convertNumToFormatString(event?.[11] || 0)}`,
        `$${convertNumToFormatString(event?.[12] || 0)}`,
        (event?.[13] * 100).toFixed(2) + "%",
        event?.[22],
        event?.[23] ? moment(event?.[23]).format("MM/DD/YYYY") : 'Not Signed',
        event?.[25] ? "Yes" : "No",
      ];
    });

    stringify([Headers, ...formattedData], (err, output) => {
      if (err) {
        console.error(err);
        return;
      }
      const blob = new Blob([output], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", CSVFileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setCsvLoading(false);
    });
  };

  return (
    <div>
      <Button
        variant="outlined"
        style={{
          height: "2.1rem",
          fontSize: "12px ",
          marginLeft: "5px",
          marginRight: "5px",
          marginTop: "5px",
          border: mode === "dark" ? "1px solid lightgray" : "1px solid green",
          borderRadius: "6px",
        }}
        onClick={() => handleExport()}
      >
        <FileDownloadIcon
          style={{
            color: "green",
          }}
        />{" "}
        <span
          style={{
            color: mode === "dark" ? "white" : "green ",
          }}
        >
          {" "}
          EXPORT ALL DATA(CSV)
        </span>
      </Button>
      {csvLoading ? <FullPageLoader /> : ""}
      <Button
        variant="outlined"
        style={{
          height: "2.1rem",
          fontSize: "12px ",
          marginLeft: "5px",
          marginRight: "5px",
          marginTop: "5px",
          border: mode === "dark" ? "1px solid lightgray" : "1px solid green",
          borderRadius: "6px",
        }}
        onClick={() =>
          handleExport({
            filter: { ...filter, hasTitle: false },
          })
        }
      >
        <FileDownloadIcon
          style={{
            color: "green",
          }}
        />{" "}
        <span
          style={{
            color: mode === "dark" ? "white" : "green ",
          }}
        >
          EXPORT FILTERED DATA(CSV)
        </span>
      </Button>
    </div>
  );
};

export default CsvExport;
