import { get, patch, post, destroy, submitFormData } from "./ServicesApi";

const statusDetail = (id, obj) => {
  const statusPromise = patch(`/v1/event/${id}/status`, obj);
  return statusPromise;
};

const createContract = (body) => {
  const contractsPromise = post("/v1/event", body);
  return contractsPromise;
};

const getContracts = (body) => {
  const contractsPromise = get("/v1/event", { params: body });
  return contractsPromise;
};

const getGraphStats = (body) => {
  const contractsPromise = get("/v1/event/charts/line", { params: body });
  return contractsPromise;
};

const getResourceStats = (body) => {
  const contractsPromise = get("/v1/event/resources", { params: body });
  return contractsPromise;
};

const getParentContractTitles = () => {
  return get("/v1/event/titles");
};

const getContractsChild = (evenId, params) => {
  return get(`/v1/event/${evenId}/children`, { params });
};

const getContractsById = (id, body) => {
  const contractsPromise = get(`/v1/event/${id}`, { params: body });
  return contractsPromise;
};

const updateContract = (id, body) => {
  const updateContractPromise = patch(`/v1/event/${id}`, body);
  return updateContractPromise;
};

/**
 * Updates contract ownership.
 * @param {number} id - Event ID
 * @param {Object} body - Payload: { soldBy?: string, createdBy?: string }
 */
const updateContractOwnership = (id, body) => {
  const updateContractPromise = patch(`/v1/event/${id}/update-ownership`, body);
  return updateContractPromise;
};

const updateContractRegion = (id, body) => {
  const updateContractPromise = patch(`/v1/event/${id}/region`, body);
  return updateContractPromise;
};

const updateContractClientType = (id, body) => {
  const updateContractPromise = patch(`/v1/event/${id}/client-type`, body);
  return updateContractPromise;
};

const deleteContract = async (id) => {
  const deleteContractPromise = await destroy(`/v1/event/${id}`);
  return deleteContractPromise;
};

const getDeletedContracts = (params) => {
  const deletedContracts = get("/v1/event/deleted", { params });
  return deletedContracts;
};

const restoreDeletedContracts = (id) => {
  const restoredContracts = patch(`/v1/event/${id}/restore`);
  return restoredContracts;
};

const uploadPDFContract = async (data) => {
  try {
    const response = await post(`/v2/adobe-sign/upload-and-send`, data);
    return response;
  } catch (error) {
    throw error;
  }
};


const contractPDFStatus = (params) => {
  return get(`/v1/adobe-sign/status`, { params });
};

const contractPDFActivity = (params) => {
  return get(`/v1/adobe-sign/events`, { params });
};

const sendContractReminder = (params) => {
  return get(`/v1/adobe-sign/send-reminder`, { params });
}

const getSignedContract = (params) => {
  return get(`/v1/adobe-sign/download-signed-agreement`, { params });
};

const getSignedContractRedirectURL = (params) => {
  return get(`/v1/adobe-sign/signed-agreement-url`, { params });
};

const cancelAgreement = (params, body) => {
  return patch(`/v1/adobe-sign/cancel-agreement`, body, params);
};

const createContractsTemplate = (data) => {
  return post(`/v1/contract-template`, data);
};

const getContractsTemplate = (params) => {
  return get(`/v1/contract-template`, { params });
};

const getContractsTemplateChanges = (params) => {
  return get(`/v1/contract-template/changes`, { params });
};

const getContractsTemplateChangesDetails = (id) => {
  return get(`/v1/contract-template/changes/${id}`);
};

const updateContractsTemplateChangeStatus = (id,status) => {
  return patch(`/v1/contract-template/changes/${id}/${status}`);
};

const updateContractsTemplate = (data) => {
  return patch(`/v1/contract-template`, data);
};

const getDraftContracts = (params) => {
  return get(`/v1/contract-template/changes/draft`, { params });

}


const uploadSignedAgreement = async (pdfFile, eventId) => {
  const formData = new FormData();
  formData.append("pdfFile", pdfFile);
  formData.append("eventId", eventId);

  try {
    const response = await submitFormData(
      `/v1/adobe-sign/upload-signed-agreement`,
      formData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  statusDetail,
  cancelAgreement,
  sendContractReminder,
  getContractsById,
  getParentContractTitles,
  updateContractRegion,
  updateContractClientType,
  createContract,
  getDraftContracts,
  getSignedContract,
  updateContract,
  contractPDFActivity,
  updateContractOwnership,
  getContracts,
  getGraphStats,
  getResourceStats,
  getContractsChild,
  deleteContract,
  restoreDeletedContracts,
  getDeletedContracts,
  uploadPDFContract,
  contractPDFStatus,
  getContractsTemplate,
  getContractsTemplateChanges,
  getContractsTemplateChangesDetails,
  updateContractsTemplateChangeStatus,
  createContractsTemplate,
  updateContractsTemplate,
  getSignedContractRedirectURL,
  uploadSignedAgreement,
};
